import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import Vant from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible';

//路由
import Router from "vue-router";

//permission control
import router from "@/router";

//导入全局样式文件
import "./styles/index.css"
import 'vant/lib/index.less';

//导入Echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

// 引入moment
import moment from 'moment'
import 'moment/locale/zh-cn'
//懒加载
import { Lazyload } from 'vant';
Vue.use(Lazyload);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

// 使用中文时间
moment.locale('zh-cn')
Vue.prototype.$moment = moment

Vue.use(Vant);
Vue.use(Router);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
