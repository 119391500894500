import Router from 'vue-router'

//1：创建路由规则
export const constantRouterMap = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home'),
        meta:{
            title: '早到天使'
        }
    },
    {
        path: '/wechat_login',
        name: 'wechatLogin',
        component: () => import('../views/auth/wechatLogin'),
        meta:{
            title: '登录'
        }
    },
    {
        path: '/grow_report',
        name: 'growReport',
        component: () => import('../views/grow/report'),
        meta:{
            title: '生长报告'
        }
    },
    {
        path: '/add_baby',
        name: 'addBaby',
        component: () => import('../views/baby/add'),
        meta:{
            title: '宝宝信息'
        }
    },
    {
        path: '/baby_list',
        name: 'babyList',
        component: () => import('../views/baby/list.vue'),
        meta:{
            title: '宝宝列表'
        }
    },
    {
        path: '/baby_curve',
        name: 'babyCurve',
        component: () => import('../views/baby/curve.vue'),
        meta:{
            title: '生长曲线'
        }
    },
    {
        path: '/add_record',
        name: 'addRecord',
        component: () => import('../views/baby/addRecord.vue'),
        meta:{
            title: '成长记录'
        }
    },
]

//2: 创建路由实例
export default new Router({
    // mode: 'hash',
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: constantRouterMap
})
